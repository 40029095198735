import React from 'react';

/** Loading MDI Icons **/
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import ClockTimeFiveOutlineIcon from 'mdi-react/ClockTimeFiveOutlineIcon';

/** Loading SVG **/
import LongDistanceDots from '@assets/svg/trips/vertical-long-distance-dots.svg';
import Line from '@assets/svg/trips/horizontal-line.svg';

/** Loading formatters **/
import { tripTimeFormatter, tripMileageFormatter } from '@services/formatters/trip/TripView.formatter';
import { formatTimeFromUTC } from '@services/formatters';

/** Loading translation helper **/
import { t } from '@helpers/i18n';

/** Loading styles **/
import './TripTrack.styles.scss';

const TripTrack = (props) => {
  const { track, drivers, selectedSubtrips, onTripClick } = props;

  /**
   * Extracting track properties from props
   */
  const { id, distance } = track;

  /**
   * get Driver info per track
   * @param {number} driverId
   * @returns {string}
   */
  const getDriverInfo = (driverId) => {
    const driverInfo = drivers.find((driver) => parseInt(driver.id) === parseInt(driverId));
    if (driverInfo) {
      return `${driverInfo.name} ${driverInfo.surname}`;
    }
    return '-';
  };

  /**
   * get Trip Type info per track
   * @param {string} tripType - possible values ['business', 'private', 'unset']
   * @returns {string}
   */
  const getTripTypeInfo = (tripType) => {
    const tripTypeValue = tripType !== 'unset' ? (track['trip-type'] === 'business' ? 'business' : 'private') : '';
    return tripTypeValue ? t(`lbl_type_${tripTypeValue}`) : '-';
  };

  /**
   * Extracting properties
   */
  const startingTrip = track['starting-position'];
  const endTrip = track['stop-position'];
  const driverId = getDriverInfo(track['driver-id']);
  const tripType = getTripTypeInfo(track['trip-type']);

  const startingDate = track.start;
  const endingDate = track.end;
  const trackDistance = tripMileageFormatter(distance);
  /**
   * trip Type click event, call parent method
   * @param {object} track
   */
  const onTripAction = (track) => {
    onTripClick(track);
  };

  /**
   * Calculating trip time
   */
  const tripTime = tripTimeFormatter(endingDate, startingDate);

  return (
    <div className={`single-trip-container ${selectedSubtrips.includes(id) && 'trip-expanded'}`}>
      <div className="flex" onClick={() => onTripAction(track)} style={{ width: '100%' }}>
        <div className={'left-text'}>
          <p className={'flex'}>
            {formatTimeFromUTC(startingDate)}
            <img src={Line} alt={'horizontal-line'} />
          </p>
          <p className={'time-margin'}>
            <MapMarkerIcon size={16} />
            <span>
              {trackDistance} {t('lbl_km')}
            </span>
          </p>
          <p className={'time-margin'}>
            <ClockTimeFiveOutlineIcon size={16} />
            <span>{tripTime}</span>
          </p>
          <p className={'time-margin'}>
            {formatTimeFromUTC(endingDate)}
            <img src={Line} alt={'horizontal-line'} />
          </p>
        </div>
        <img src={LongDistanceDots} alt={'long-dots'} />
        <div className={'right-text'}>
          <p>
            {startingTrip.street}, {startingTrip.zip} {startingTrip.city}
          </p>
          <p className={'time-margin'}>
            {t('driver')} {driverId}
          </p>
          <p className={'time-margin'}>
            {t('lbl_type')} {tripType}{' '}
          </p>
          <p className={'time-margin'}>
            {endTrip.street}, {endTrip.zip} {endTrip.city}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TripTrack;
