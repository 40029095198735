export const priceLevel = (index, length) => {
  if (index <= length / 3) {
    return 'low';
  }

  if (index <= length / 1.5) {
    return 'middle';
  }

  return 'high';
};

export const lineSymbol = {
  path: 'M 3 4 L 3 0 L 4 2 M 3 0 L 2 2',
  fillColor: 'blue',
  strokeColor: 'blue',
  strokeWeight: 2,
  strokeOpacity: 1
};
