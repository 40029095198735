import React from 'react';

/** Loading components **/
import SimpleCard from '@components/Card/SimpleCard';
import Avatar from '@components/User/Avatar';
import { LicensePlate } from '@components/Car/LicensePlate';
import { dateFormatted, mileageFormatter, formatTimeFromUTC } from '@services/formatters';
import { CARD_TYPES } from '@components/Card';

/** Loading SVG **/
import DotsVertical from '@assets/svg/trips/verticalDots.svg';

/** Loading MDI Icons **/
import FlareIcon from 'mdi-react/FlareIcon';
import BriefcaseOutlineIcon from 'mdi-react/BriefcaseOutlineIcon';
import AccountStarOutlineIcon from 'mdi-react/AccountStarOutlineIcon';

/** Loading translation helper **/
import { t } from '@helpers/i18n';

/** Loading styles **/
import './TripCard.styles.scss';

const TripCard = ({ trip, onClick, drivers }) => {
  const { start, end, total } = trip;
  const licencePlate = trip['license-plate'];
  const startAddress = trip['start-address'];
  const endAddress = trip['end-address'];
  const driverIds = trip['driver-ids'] || [];
  const type = trip['trip-type-overall'];

  /**
   * Show blue Avatar and Multi User text
   * @returns {JSX.Element}
   */
  const multiUser = () => {
    return (
      <div className={'multi-user'}>
        <Avatar src={''} iconBackgroundColor={'blue'} small={true} />
        <div className={'trip-info'}>
          <p>{t('multiple_drivers')}</p>
        </div>
      </div>
    );
  };

  /**
   * Render Trip Card Left Block
   **/
  const renderLeft = () => {
    if (driverIds?.length > 1) {
      return multiUser();
    } else {
      const driver = driverIds?.length ? drivers?.filter((driver) => parseInt(driver.id) === driverIds[0]) : [];
      return (
        <>
          <Avatar src={driver[0]?.avatar} iconBackgroundColor={'transparent'} small={true} />
          <div className={'trip-info'}>
            <p>{driver[0]?.name}</p>
            <p>{driver[0]?.surname}</p>
          </div>
        </>
      );
    }
  };

  /**
   * Render Trips Card Right Block
   **/
  const renderRight = () => {
    return (
      <>
        <p>{start ? dateFormatted(start) : '-'}</p>
        <p>
          {mileageFormatter(Math.round(total?.kilometrage))} {t('lbl_km')}
        </p>
        <LicensePlate country="D" license={licencePlate} />
      </>
    );
  };

  /**
   * Show icon depending on trip type
   * @returns {JSX.Element}
   */
  const typeOfTrip = () => {
    if (type === 'mixed') {
      return <FlareIcon color={'#00587F'} />;
    } else if (type === 'business') {
      return <BriefcaseOutlineIcon color={'#00587F'} />;
    } else if (type === 'private') {
      return <AccountStarOutlineIcon color={'#00587F'} />;
    } else {
      return <div style={{ width: '24px', height: '24px' }} />;
    }
  };

  /**
   * Render Trip Card Bottom Block
   * @returns {JSX.Element}
   */
  const renderBottom = () => {
    return (
      <div className={'trips-bottom'}>
        {typeOfTrip()}
        <div style={{ width: '40px' }}>
          <p>{start ? formatTimeFromUTC(start) : '-'}</p>
          <p className={'margin-top-8'}>{end ? formatTimeFromUTC(end) : '-'}</p>
        </div>
        <img src={DotsVertical} alt="dots" />
        <div>
          <p className={'trip-address'}>{startAddress ?? '-'}</p>
          <p className={'trip-address margin-top-8'}>{endAddress ?? '-'}</p>
        </div>
      </div>
    );
  };

  /**
   * Return proper class for right border of Trip card
   */
  const tripCardColor = () => {
    if (trip.complete) {
      return `damage_border_${CARD_TYPES.GREEN}`;
    }
    return `damage_border_${CARD_TYPES.RED}`;
  };

  return (
    <div className="trip" onClick={onClick}>
      <SimpleCard className={`damage-card ${tripCardColor()}`} renderLeft={renderLeft} renderRight={renderRight}>
        {renderBottom()}
      </SimpleCard>
    </div>
  );
};

export default TripCard;
