import React, { useEffect, useState } from 'react';
import { DriverPopup } from '../DriverPopup';
import ServicePartnerPopup from '../ServicePartnerPopup';
import GasStationPopup from '../GasStationPopup';
import classnames from 'classnames';
import { getVehicleStatusIconColor } from '@helpers/common/vehicles';

import './Marker.styles.scss';

export const MARKER_STYLES = {
  DOT_MARKER: 'dot-marker',
  ICON_MARKER: 'icon-marker'
};

export const MARKER_TYPES = {
  CAR: 'passenger_car',
  VAN: 'small_transporter',
  TRAN: 'transporter',
  SERVICE: 'ServicePartner',
  STATION: 'gas-station'
};

export const transportMarkers = [MARKER_TYPES.CAR, MARKER_TYPES.VAN, MARKER_TYPES.TRAN];

export const markersWithInfo = [MARKER_TYPES.CAR, MARKER_TYPES.VAN, MARKER_TYPES.TRAN];

const Marker = ({
  type,
  name,
  brand,
  price,
  speed,
  rating,
  userCar,
  fuelLevel,
  priceLevel,
  customerCity,
  customerPlace,
  licensePlate,
  started,
  onMarkerClick,
  markerIcon,
  marker,
  hoverMarker,
  hoverMarkerStation,
  hoverMarkerService
}) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setHovered(
      hoverMarkerStation?.id === marker.id ||
        hoverMarkerService?.id === marker.id ||
        (hoverMarker && (hoverMarker?.[0] === marker.id || hoverMarker.id === marker.id))
    );
  }, [hoverMarker, marker, hoverMarkerStation, hoverMarkerService]);

  const markerType = () => {
    return type === MARKER_TYPES.STATION ? MARKER_STYLES.DOT_MARKER : MARKER_STYLES.ICON_MARKER;
  };

  const renderInfo = () => {
    if (markersWithInfo.includes(type)) {
      return (
        <>
          <div className={classnames('marker-info', type)}>
            <div className="info-line1">{licensePlate}</div>
            <div className="info-line2">{customerCity}</div>
          </div>
          <div className={`marker-dot ${getVehicleStatusIconColor(started)}`} />
        </>
      );
    }
  };

  const renderPopup = () => {
    if (!hovered) {
      return null;
    }

    if (type === MARKER_TYPES.STATION) {
      return <GasStationPopup brand={brand} price={price} priceLevel={priceLevel} />;
    }

    if (type === MARKER_TYPES.SERVICE) {
      return <ServicePartnerPopup name={name} rating={rating} customerPlace={customerPlace} />;
    }
    return (
      <DriverPopup
        speed={speed}
        username={name}
        userCar={userCar}
        fuelLevel={fuelLevel}
        customerCity={customerCity}
        customerPlace={customerPlace}
      />
    );
  };

  const isMarkerClicked = () =>
    hoverMarkerStation?.id === marker.id ||
    hoverMarkerService?.id === marker.id ||
    hoverMarker?.id === marker.id ||
    hoverMarker?.[0] === marker.id;

  return (
    <div
      className="map-marker"
      onMouseEnter={() => !isMarkerClicked() && setHovered(true)}
      onMouseLeave={() => !isMarkerClicked() && setHovered(false)}>
      <div className={classnames('marker-container', markerType(), priceLevel, `${type}-mark`)} onClick={onMarkerClick}>
        {type !== MARKER_TYPES.STATION && (
          <>
            {renderInfo()}
            <div className="icon-background" />
            {markerIcon}
          </>
        )}
      </div>
      {renderPopup()}
    </div>
  );
};

export default Marker;
